import React, { Component } from "react"
import { graphql } from "gatsby"
// import Img from "gatsby-image"
//import PubSub from 'pubsub-js';
import SEO from "../components/SEO"
import PageScroller from "../components/PageScroller"
// import { WrapperContext } from '../components/Layout'

class PageProject extends Component {
    // static contextType = WrapperContext

    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        // console.log(this.props)
        const { uid, data } = this.props.data.project
        const {
            title,
            texte,
            image_featured,
            // images
        } = data
        // console.log(texte)
        return (
            <div className="page-project">
                <SEO
                    pageTitle={title.text}
                    pageDescription={texte.text}
                    pageImage={image_featured.url}
                    isPage={true}
                    template="template-project"
                    // pathname={location.pathname}
                />

                <PageScroller current={uid} />
            </div>
        )
    }
}

export default PageProject

export const pageQuery = graphql`
    query ProjectBySlug($slug: String!, $locale: String!) {
        project: prismicProject(uid: { eq: $slug }, lang: { eq: $locale }) {
            uid
            data {
                ...projet
            }
        }
    }
`
